<template>
  <div>
    <!-- 企业详细信息 -->
    <div class="bottom_right" v-if="i == 1">
      <!-- <div class="huanying">欢迎您：{{ compObj.ContactPerson }}</div> -->
      <div class="smalltitle">企业详细信息</div>
      <div class="userinforbox">
        <div class="userlogo">
          <img :src="compObj.Logo" alt="" /><span>{{ compObj.Name }}</span>
        </div>
        <div class="usertitle"><span>企业介绍：</span></div>
        <div
          class="htmlbox"
          v-html="compObj.Description"
          ref="content"
          @click="imageEnlargement"
        ></div>
      </div>
      <div class="inforedint">
        <span @click="gettags(4)">编辑企业详细信息</span>
      </div>
    </div>
    <!-- 修改企业详细信息 -->
    <div class="bottom_right" v-if="i == 4">
      <div class="smalltitle">编辑企业详细信息</div>
      <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
        <el-form-item label="上传Logo" :required="true">
          <span slot="label"
            >上传Logo<span style="color: red; font-size: 12px">
              (建议尺寸 80x80)</span
            ></span
          >
          <template>
            <div v-if="LogoList.length > 0">
              <div
                class="demo-upload-list"
                :key="index"
                v-for="(item, index) in LogoList"
              >
                <template style="height: 150px; width: 150px">
                  <div class="demo-upload-list-cover">
                    <img :src="item.url" class="imputImg" />
                    <!-- <img class="delimg"  src="../assets/img/del.png" alt="" /> -->
                    <i
                      class="el-icon-circle-close delimg"
                      @click="RemoveLogo(item)"
                    ></i>
                  </div>
                </template>
              </div>
            </div>
            <!-- <Upload> -->
            <el-upload
              ref="upload"
              v-if="LogoList.length == 0"
              :before-upload="AddFileLogo"
              type="drag"
              action=""
              style="display: inline-block; width: 100%"
            >
              <div style="width: 58px; height: 58px; line-height: 58px">
                <i class="el-icon-camera-solid"></i>
                <!-- <Icon type="ios-camera"
                        size="20"></Icon> -->
              </div>
            </el-upload>
            <!-- </Upload> -->
          </template>
        </el-form-item>

        <el-form-item label="企业介绍" prop="introduce">
          <editor-bar
            v-model="ruleForm.Description"
            :value="ruleForm.Description"
            @change="getEditor"
            ref="weditor"
          ></editor-bar>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitcompany('ruleForm')"
            >确定</el-button
          >
          <el-button @click="cancleeditCom(1)">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

import EditorBar from "../components/wangeditor";
import timeFormat from "../util/time.js";

var isvalidPhone = function (str) {
  const reg = /^(1[3456789]\d{9})$|^(0\d{2,3}-\d{7,8})$/;
  return reg.test(str);
};
var validPhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入电话号码"));
  } else if (!isvalidPhone(value)) {
    callback(new Error("请输入正确的11位手机号码或座机号码"));
  } else {
    callback();
  }
};
export default {
  components: {
    EditorBar,
  },
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("年龄不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (value < 18) {
            callback(new Error("必须年满18岁"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      LogoList: [],
      // 招聘类型
      zhaopintypelist: [],

      // 营业执照
      yyzzlist: [],

      // 企业类型列表
      companyType: null,
      // 企业标签
      companyTags: null,
      value2: true,
      tableData: [
        {
          date: "2016-05-02",
          name: "互联网软件开发",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "小程序开发",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "h5开发",
          address: "上海市普陀区金沙江路 1519 弄",
        },
      ],
      //   富文本
      editor: null,

      info_: null,
      compObj: {},
      index: 1,
      i: 1,
      dialogVisible: false,
      ruleForms: {
        pass: "",
        checkPass: "",
        age: "",
      },
      ruless: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        age: [{ validator: checkAge, trigger: "blur" }],
      },
      // 企业信息列表
      ruleForm: {},
      DeleteImgSysID: "",

      //服务
      serverules: {
        Title: [
          // 企业名称
          { required: true, message: "请输入企业名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "2 到 50 个字符",
            trigger: "blur",
          },
        ],

        // 联系人
        ContactPerson: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
          { min: 2, max: 20, message: "2 到 20 个字符", trigger: "blur" },
        ],
        // 联系电话
        Tel: [{ required: true, trigger: "blur", validator: validPhone }],

        // 企业标签
        CateName: [
          {
            required: true,
            message: "请选择类型",
            trigger: "blur",
          },
        ],
      },
      value: "",
      servertypelist: [],
      addserverList: {},
      zhaopindata: [],
      itemzhaopin: {},
      gongxundata: [],
      itemgongxu: {},
      gongxu: {},
      gongxutype: [
        {
          typename: "供应",
          laby: "0",
        },
        {
          typename: "需求",
          laby: "1",
        },
      ],
      addzhaopinlist: {},
      addgongxu: {},
      companyProfession: [],
      imgArr:[],
    };
  },
  methods: {
    imageEnlargement(e) {
   
      if (e.target.nodeName == "IMG") {
        //判断点击富文本内容为img图片
        console.log(e.target.currentSrc);
        this.imgArr[0] = e.target.currentSrc;
        console.log(this.imgArr);

         this.$viewerApi({
          images: this.imgArr
        })
     
      } else {
        console.log("点击内容不为img");
      }
    },
    //附件列表
    LoadFiles(sysid) {
      if (sysid == "") return;
      this.loading = true;
      let that = this;
      that
        .$post("/api/File/List", {
          MainSysID: sysid,
          Mark: "Logo",
        })
        .then(function (res) {
          let List = [];
          that.LogoList = [];
          List = res.Obj;

          List.forEach((i) => {
            that.LogoList.push({
              name: i["SourceName"] + "." + i["Extension"],
              url: that.urlOA + i["URL"].substr(1),
              size: -1,
              SysID: i.SysID,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //删除logo
    RemoveLogo(Obj) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (Obj.size === -1) {
            this.LogoList = this.LogoList.filter((v, i) => {
              return v != Obj;
            });
            this.DeleteImgSysID = Obj.SysID;
          } else {
            this.LogoList = this.LogoList.filter((v, i) => {
              return v != Obj;
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      // if (this.loading) return;
    },
    //上传logo附件
    AddFileLogo(file) {
      let fileName = file.name;
      let index = fileName.lastIndexOf(".");
      if (index > 0) {
        let suffixName = fileName.substring(index + 1).toLowerCase();
        if (
          suffixName == "jpg" ||
          suffixName == "jpeg" ||
          suffixName == "png"
        ) {
          this.file = file;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const _base64 = reader.result;
            file.url = _base64;
            this.LogoList = this.LogoList.concat(file);
          };
        } else {
          this.$message.warning("上传附件格式有误,请重新上传!");
        }
      }
      return false;
    },
    getradio(e) {
      console.log(e);
    },
    getindex() {
      this.$router.push("/");
    },
    // 获取企业信息
    getnewsList() {
      let that = this;
      let Account = window.localStorage.getItem("Account");
      that
        .$post("/api/UserInfo/ObjAccount", {
          Account: Account,
        })
        .then(function (data) {
          console.log(data);
          let company = data.Obj.CompanyObj;
          // 图片处理
          company.Logo = that.urlOA + company.Logo.substr(1);
          // 时间处理
          if (company.OpenTime) {
            company.OpenTime = timeFormat.timeFormat(company.OpenTime, 1);
          }
          company.Tags = company.Tags.split(",");
          that.ruleForm = company;
          that.compObj = company;
          that.LoadFiles(company.SysID);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 企业类型
    gettype() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "NewsCompanyType",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.companyType = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 招聘类型
    getzhaopintype() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "AdvertiseType",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.zhaopintypelist = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 企业服务类型
    getservetype() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "CompanyServeType",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.servertypelist = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 企业标签
    getTags() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "CompanyTag",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.companyTags = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 企业行业
    getProfession() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "CompanyProfession",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.companyProfession = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 编辑企业信息
    submitcompany() {
      let that = this;

      let ruleForm = that.ruleForm;

      let formData = new FormData();

      let LogoList = that.LogoList;
      if (LogoList.length == 0) {
        that.$message.error("请上传帐号Logo");
        return;
      }
      if (ruleForm.Tags.length > 1) {
        ruleForm.Tags = ruleForm.Tags.join(",");
      } else if (ruleForm.Tags.length == 1) {
        ruleForm.Tags = ruleForm.Tags[0];
      }
      LogoList.forEach((file, i) => {
        formData.append("Logo", file);
      });
      formData.append("DeleteImgSysID", that.DeleteImgSysID);
      formData.append("Obj", JSON.stringify(ruleForm));
      that.$post("/api/Company/CompanyEdit", formData).then(function (data) {
        console.log(data);
        if (data.Result) {
          that.$message.success("编辑企业信息成功");

          that.getnewsList();
          that.i = 1;
        }
      });
    },
    // 取消编辑企业信息
    cancelserve(i) {
      this.i = i;
      this.addserverList = {};
    },
    cancleeditCom(i) {
      this.item = {};
      this.i = i;
    },
    // 企业服务信息
    getserveList() {
      let that = this;
      that
        .$post("/api/Serve/List", {
          CState: true,
          CompanySysID: "e0ae8590-b1a3-4b10-ad07-9917fe73b40c",
          Sort: "CreateOn desc",
        })
        .then(function (data) {
          console.log(data);
          let company = data.Obj;
          console.log(timeFormat.timeFormat);
          company.forEach((v) => {
            v.CreateOn = timeFormat.timeFormat(v.CreateOn, 1);
          });

          that.tableData = company;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 开关服务
    getserveopen(row) {
      console.log(row);
      let that = this;
      that
        .$post("/api/Serve/ChangeInfo", {
          SysID: row.SysID,
          State: !row.State,
        })
        .then(function (data) {
          console.log(data);
          that.$message.success("操作成功");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleted(url, row) {
      let that = this;
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        that
          .$post(url, { SysID: row.SysID })
          .then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.getserveList();
              // 招标
              that.getzhabiaoList();
              // 供需
              that.getgongxuList();
            }
          })
          .error(() => {
            this.$message({
              type: "info",
              message: "删除失败",
            });
          });
      });
    },
    // 服务的富文本编辑器
    getserveaddEditor(value) {
      console.log(value);
      this.addserverList.Description = value;
    },
    getserveIntro(value) {
      console.log(value);
      this.addserverList.Intro = value;
    },
    // 招聘的富文本编辑器
    getzhaopinddEditor(value) {
      console.log(value);
      this.addzhaopinlist.Description = value;
    },
    getzhaopinIntro(value) {
      console.log(value);
      this.addzhaopinlist.Intro = value;
    },

    // 供需的的富文本编辑器
    getgongxuadd(value) {
      console.log(value);
      this.addgongxu.Description = value;
    },
    getgongxuIntro(value) {
      console.log(value);
      this.addgongxu.Intro = value;
    },
    // 新增服务
    addserve(formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          let item = that.addserverList;
          item.State = true;
          item.CompanySysID = "e0ae8590-b1a3-4b10-ad07-9917fe73b40c";
          let formData = new FormData();
          formData.append("Obj", JSON.stringify(item));
          that.$post("/api/Serve/Edit", formData).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message.success("添加服务成功");
              setTimeout(() => {
                that.i = 5;
                that.addserverList = {};
              }, 1000);
              that.getserveList();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });

      console.log(this.addserverList);
    },
    // 新增供需
    addgongxuinfor(formName) {
      console.log(this.addgongxu);
      let that = this;

      that.$refs[formName].validate((valid) => {
        if (valid) {
          let item = that.addgongxu;
          item.State = true;
          item.CompanySysID = "e0ae8590-b1a3-4b10-ad07-9917fe73b40c";
          let formData = new FormData();
          formData.append("Obj", JSON.stringify(item));
          that.$post("/api/Interaction/Edit", formData).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message.success("添加互动成功");
              that.getgongxuList();
              that.i = 13;
              that.addgongxu = {};
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑服务
    submitserve(formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          let item = that.item;
          item.CateSysID = item.CateName;
          let formData = new FormData();
          formData.append("Obj", JSON.stringify(item));
          that.$post("/api/Serve/Edit", formData).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message.success("编辑企业服务成功");
              that.getserveList();
              that.i = 5;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 取消编辑服务
    cancleserve() {
      let that = this;
      that.i = 5;
      that.item = {};
    },
    // 取消编辑招聘
    canclezhaopin() {
      let that = this;
      that.i = 9;
      that.addzhaopinlist = {};
    },
    // 取消编辑互动
    canclegongxu() {
      let that = this;
      that.i = 13;
      that.gongxu = {};
    },
    // 企业富文本
    getEditor(value) {
      console.log(value);

      this.ruleForm.Description = value;
    },
    // 服务编辑富文本
    getserveEditor(value) {
      this.item.Description = value;
    },
    // 招聘列表

    getzhabiaoList() {
      let that = this;
      that
        .$post("/api/Advertise/List", {
          CompanySysID: "e0ae8590-b1a3-4b10-ad07-9917fe73b40c",
          Sort: "CreateOn desc",
        })
        .then(function (data) {
          console.log(data);
          let company = data.Obj;
          console.log(timeFormat.timeFormat);
          company.forEach((v) => {
            v.CreateOn = timeFormat.timeFormat(v.CreateOn, 1);
          });

          that.zhaopindata = company;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 开关招聘
    getzhaopinopen(row) {
      console.log(row);
      let that = this;
      that
        .$post("/api/Advertise/ChangeInfo", {
          SysID: row.SysID,
          State: !row.State,
        })
        .then(function (data) {
          console.log(data);
          that.$message.success("操作成功", "success");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 编辑招聘信息
    zhaopinedit(formName) {
      let that = this;
      let item = that.itemzhaopin;

      that.$refs[formName].validate((valid) => {
        if (valid) {
          item.TypeSysID = item.TypeName;
          let formData = new FormData();
          formData.append("Obj", JSON.stringify(item));
          that.$post("/api/Serve/Edit", formData).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message.success("编辑招聘信息成功");

              that.getzhabiaoList();
              that.i = 9;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 添加招聘信息
    addzhaopin(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.addzhaopinlist);

          let item = that.addzhaopinlist;
          item.State = true;
          item.CompanySysID = "e0ae8590-b1a3-4b10-ad07-9917fe73b40c";

          that.$post("/api/Advertise/Edit", item).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message.success("发布职位成功");
              that.getzhabiaoList();
              that.i = 9;
              that.addzhaopinlist = {};
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getperson() {
      this.$router.push("/personalCenter");
    },
    getnews() {
      this.$router.push("/newsCenter");
    },
    getcarrier() {
      this.$router.push("/carrierCenter");
    },
    // 服务
    gettags(i, item) {
      console.log(item);
      this.i = i;
      if (item) {
        this.ruleForm = item;
      }
    },
    // 招聘
    gettagszhao(i, item) {
      console.log(item);
      this.i = i;
      if (item) {
        this.itemzhaopin = item;
        this.zhaopin = item;
      }
    },
    getgongxuItem(i, item) {
      console.log(item);
      this.i = i;
      if (item) {
        this.itemgongxu = item;
        item.Type = item.Type ? "需求" : "供应";
        this.gongxu = item;
      }
    },
    // 供需列表
    getgongxuList() {
      let that = this;
      that
        .$post("/api/Interaction/List", {
          CState: true,
          CompanySysID: "e0ae8590-b1a3-4b10-ad07-9917fe73b40c",
          Sort: "CreateOn desc",
        })
        .then(function (data) {
          console.log(data);
          let company = data.Obj;
          console.log(timeFormat.timeFormat);
          company.forEach((v) => {
            v.CreateOn = timeFormat.timeFormat(v.CreateOn, 1);
          });

          that.gongxundata = company;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 开关供需
    getgongxuopen(row) {
      console.log(row);
      let that = this;
      that
        .$post("/api/Interaction/ChangeInfo", {
          SysID: row.SysID,
          State: !row.State,
        })
        .then(function (data) {
          console.log(data);
          that.$message.success("操作成功", "success");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getgongxuEditor(value) {
      this.gongxu.Description = value;
    },
    // 编辑供需
    hudoongEdit(formName) {
      let that = this;

      that.$refs[formName].validate((valid) => {
        if (valid) {
          let gongxu = that.gongxu;
          if (gongxu.Type == "供应") {
            gongxu.Type = 0;
          }
          if (gongxu.Type == "需求") {
            gongxu.Type = 1;
          }
          let formData = new FormData();
          formData.append("Obj", JSON.stringify(gongxu));
          that.$post("/api/Interaction/Edit", formData).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message.success("编辑供需成功");

              that.getgongxuList();
              that.i = 13;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    handlePictureCardPreview(file) {
      console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 营业执照
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },

  mounted() {
    this.getnewsList();
    this.getserveList();
    // 企业类型
    this.gettype();
    // 企业标签
    this.getTags();
    // 企业服务类型
    this.getservetype();
    // 招标
    this.getzhabiaoList();
    // 供需
    this.getgongxuList();
    // 招聘类型
    this.getzhaopintype();
    // 企业行业
    this.getProfession();
  },
};
</script>
<style scoped  lang="scss">
.bottom_right ::v-deep .el-select .el-input__inner {
  width: 320px;
}
.htmlbox {
  float: left;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}
.htmlbox ::v-deep img {
  width: 100%;
}
.htmlbox ::v-deep p {
  line-height: 30px;
  font-size: 15px;
  color: #555;
}
.htmlbox ::v-deep dt {
  line-height: 40px;
  font-weight: 600;
  font-size: 15px;
  color: #555;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.textbox {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f6f6f6;
}
.topbox {
  width: 100%;
  background-color: #fff;
  height: 100px;
  margin-top: 30px;
}
.topboxinfor {
  width: 1200px;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: aquamarine;
}
.topboxinfor img {
  float: left;
  height: 60px;
  margin-top: 20px;
  margin-right: 40px;
}
.top_btnbox {
  position: relative;
  top: 0;
  float: left;
  margin-left: 20px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  // background-color: red;
  em {
    position: absolute;
    font-style: none;
    height: 20px;
    line-height: 20px;
    width: 20px;
    right: 10px;
    top: 10px;
    border-radius: 50%;
    background-color: red;
    font-size: 10px;
    color: #fff;
    text-align: center;
  }
  img {
    position: absolute;
    height: 40px;
    width: 40px;
    left: 30px;
    top: 10px;
    margin: 0;
  }
  span {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 60px;
    height: 30px;
    line-height: 30px;
    color: #1890ff;
    font-size: 15px;
  }
}
.bottombox {
  width: 1200px;
  min-height: 600px;
  margin-left: 50%;
  margin-top: 20px;
  transform: translateX(-50%);
  // background-color: aquamarine;
  overflow: hidden;
}
.bottom_left {
  float: left;
  // background-color: red;
  width: 300px;
  min-height: 600px;
}
.bottom_right {
  float: left;
  width: 880px;
  margin-left: 20px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #eee;
  min-height: 600px;
  box-sizing: border-box;
}
.tabbox {
  width: 100%;
  border: 1px solid #eee;
  background-color: #fff;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.tabtitle {
  padding-left: 30px;
  box-sizing: border-box;
  height: 50px;
  line-height: 55px;
  font-size: 16px;
}
.tabbtn {
  height: 35px;
  line-height: 35px;
  padding-left: 40px;
  box-sizing: border-box;
  font-size: 14px;
  color: #555;
  cursor: pointer;
}
.active {
  border-left: 3px solid blue;
  background-color: #f6f6f6;
}
.active1 {
  background-color: #e9e9e9;
}
.huanying {
  height: 60px;
  line-height: 30px;
  padding: 15px;
  width: 100%;
  background-color: #d9edf7;
  box-sizing: border-box;
  font-size: 14px;
  color: #666;
}
.smalltitle {
  position: relative;
  height: 36px;
  line-height: 6px;
  font-size: 14px;
  background-color: #f1f1f1;
  margin-top: 15px;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.heard {
  position: relative;
  height: 30px;
  width: 100%;
  span {
    cursor: pointer;
    position: absolute;
    height: 30px;
    width: 100px;
    border-radius: 3px;
    color: #1890ff;
    font-size: 14px;
    line-height: 32px;
    background-color: #fff;
    vertical-align: middle;
    img {
      display: inline-block;
      height: 30px;
      width: 30px;
      // vertical-align:middle;
      vertical-align: middle;
    }
  }
}
.userinforbox {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px;
}
.username {
  // display: inline-block;
  float: left;
  width: 50%;
  padding: 10px 15px;
  height: 60px;
  line-height: 40px;
  font-size: 14px;
  color: #63cafd;
  box-sizing: border-box;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  span {
    display: inline-block;
    height: 40px;
    width: 120px;
    line-height: 40px;
    color: #666;
    // background-color: #D9EDF7;
  }
  i {
    font-style: normal;
  }
}
.usertitle {
  float: left;
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  margin-top: 20px;
  padding: 0 15px;
  box-sizing: border-box;
}
.userlogo {
  // height: 100px;
  font-weight: 700;

  line-height: 100px;
  font-size: 18px;

  span {
    display: inline-block;
    // width: 100px;
    font-size: 14px;

    color: #666;
  }
  img {
    width: 200px;
    // width: 80px;
    vertical-align: middle;
    margin-right: 30px;
  }
}
.inforedint {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: #f1f1f1;
  span {
    cursor: pointer;
    background-color: #1890ff;
    padding: 10px 30px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}

.password {
  display: flex;
  align-items: center;
  // height: 80px;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}
.left {
  flex: 6;
  .left_title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    padding-left: 15px;
    box-sizing: border-box;
    color: #555;
  }
  .left_text {
    color: #555;
    line-height: 30px;
    font-size: 14px;
    padding-left: 15px;
    box-sizing: border-box;
  }
}
.right {
  flex: 1;

  span {
    background-color: #1890ff;
    cursor: pointer;
    padding: 3px 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}

.bottom_right::v-deep .el-form-item__content {
  width: 80%;

  margin-top: 60px;
  img {
    float: left;
    padding-bottom: 30px;
  }
  .delimg {
    float: left;
    margin-top: -15px;
    margin-left: -15px;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    z-index: 9999999999;
    border-radius: 50%;
    background-color: red;
  }
}
.el-icon-camera-solid:before {
  font-size: 24px;
}

@media (max-width: 500px) {
  .bottom_right ::v-deep .el-select .el-input__inner {
    width: 320px;
  }
  .htmlbox {
    float: left;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }
  .htmlbox ::v-deep img {
    width: 100%;
  }
  .htmlbox ::v-deep p {
    line-height: 30px;
    font-size: 15px;
    color: #555;
  }
  .htmlbox ::v-deep dt {
    line-height: 40px;
    font-weight: 600;
    font-size: 15px;
    color: #555;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .textbox {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #f6f6f6;
  }
  .topbox {
    width: 100%;
    background-color: #fff;
    height: 100px;
    margin-top: 30px;
  }
  .topboxinfor {
    width: 1200px;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
    // background-color: aquamarine;
  }
  .topboxinfor img {
    float: left;
    height: 60px;
    margin-top: 20px;
    margin-right: 40px;
  }
  .top_btnbox {
    position: relative;
    top: 0;
    float: left;
    margin-left: 20px;
    height: 100px;
    width: 100px;
    cursor: pointer;
    // background-color: red;
    em {
      position: absolute;
      font-style: none;
      height: 20px;
      line-height: 20px;
      width: 20px;
      right: 10px;
      top: 10px;
      border-radius: 50%;
      background-color: red;
      font-size: 10px;
      color: #fff;
      text-align: center;
    }
    img {
      position: absolute;
      height: 40px;
      width: 40px;
      left: 30px;
      top: 10px;
      margin: 0;
    }
    span {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 60px;
      height: 30px;
      line-height: 30px;
      color: #1890ff;
      font-size: 15px;
    }
  }
  .bottombox {
    width: 1200px;
    min-height: 600px;
    margin-left: 50%;
    margin-top: 20px;
    transform: translateX(-50%);
    // background-color: aquamarine;
    overflow: hidden;
  }
  .bottom_left {
    float: left;
    // background-color: red;
    width: 300px;
    min-height: 600px;
  }
  .bottom_right {
    float: left;
    width: 100%;
    margin-left: 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #eee;
    min-height: 600px;
    box-sizing: border-box;
  }
  .tabbox {
    width: 100%;
    border: 1px solid #eee;
    background-color: #fff;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
  .tabtitle {
    padding-left: 30px;
    box-sizing: border-box;
    height: 50px;
    line-height: 55px;
    font-size: 16px;
  }
  .tabbtn {
    height: 35px;
    line-height: 35px;
    padding-left: 40px;
    box-sizing: border-box;
    font-size: 14px;
    color: #555;
    cursor: pointer;
  }
  .active {
    border-left: 3px solid blue;
    background-color: #f6f6f6;
  }
  .active1 {
    background-color: #e9e9e9;
  }
  .huanying {
    height: 60px;
    line-height: 30px;
    padding: 15px;
    width: 100%;
    background-color: #d9edf7;
    box-sizing: border-box;
    font-size: 14px;
    color: #666;
  }
  .smalltitle {
    position: relative;
    height: 36px;
    line-height: 6px;
    font-size: 14px;
    background-color: #f1f1f1;
    margin-top: 15px;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .heard {
    position: relative;
    height: 30px;
    width: 100%;
    span {
      cursor: pointer;
      position: absolute;
      height: 30px;
      width: 100px;
      border-radius: 3px;
      color: #1890ff;
      font-size: 14px;
      line-height: 32px;
      background-color: #fff;
      vertical-align: middle;
      img {
        display: inline-block;
        height: 30px;
        width: 30px;
        // vertical-align:middle;
        vertical-align: middle;
      }
    }
  }
  .userinforbox {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 15px;
  }
  .username {
    // display: inline-block;
    float: left;
    width: 50%;
    padding: 10px 15px;
    height: 60px;
    line-height: 40px;
    font-size: 14px;
    color: #63cafd;
    box-sizing: border-box;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    span {
      display: inline-block;
      height: 40px;
      width: 120px;
      line-height: 40px;
      color: #666;
      // background-color: #D9EDF7;
    }
    i {
      font-style: normal;
    }
  }
  .usertitle {
    float: left;
    width: 100%;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    margin-top: 20px;
    padding: 0 15px;
    box-sizing: border-box;
  }
  .userlogo {
    // height: 100px;
    font-weight: 700;

    line-height: 100px;
    font-size: 18px;

    span {
      display: inline-block;
      width: 100%;
      font-size: 18px;

      color: #000;
    }
    img {
      width: 100%;
      // width: 80px;
      vertical-align: middle;
      // margin-right: 30px;
    }
  }
  .inforedint {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background-color: #f1f1f1;
    span {
      cursor: pointer;
      background-color: #1890ff;
      padding: 10px 30px;
      color: #fff;
      font-size: 15px;
      border-radius: 3px;
    }
  }

  .password {
    display: flex;
    align-items: center;
    // height: 80px;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
  }
  .left {
    flex: 6;
    .left_title {
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      font-weight: 600;
      padding-left: 15px;
      box-sizing: border-box;
      color: #555;
    }
    .left_text {
      color: #555;
      line-height: 30px;
      font-size: 14px;
      padding-left: 15px;
      box-sizing: border-box;
    }
  }
  .right {
    flex: 1;

    span {
      background-color: #1890ff;
      cursor: pointer;
      padding: 3px 20px;
      color: #fff;
      font-size: 15px;
      border-radius: 3px;
    }
  }

  .bottom_right::v-deep .el-form-item__content {
    width: 100%;

    margin-top: 60px;
    img {
      float: left;
      padding-bottom: 30px;
    }
    .delimg {
      float: left;
      margin-top: -15px;
      margin-left: -15px;
      color: #fff;
      font-size: 30px;
      cursor: pointer;
      height: 30px;
      width: 30px;
      z-index: 9999999999;
      border-radius: 50%;
      background-color: red;
    }
  }
  .el-icon-camera-solid:before {
    font-size: 24px;
  }
}
</style>